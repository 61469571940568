import { useStaticQuery, graphql } from 'gatsby';
import { getRichTextFromQuery } from '../common/utils';

const useContentfulTerms = () => {
  const { allContentfulTerms } = useStaticQuery(
    graphql`
    {
      allContentfulTerms(sort: {order: ASC, fields: order}) {
        edges {
          node {
            title
            order
            description {
              json
            }
          }
        }
      }
    }
    `
  );
  
  return getRichTextFromQuery(allContentfulTerms.edges);
};

export default useContentfulTerms;