import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import Collapse from '@kunukn/react-collapse';
import styles from './styles/AccordionList.module.scss';
import { getHash } from '../common/utils';

const openClassName = classnames(styles.open, 'open');

const getItemAnchorIndex = (items, hash) => {
  return items.findIndex((item) => {
    const { anchor } = item;
    if (anchor) {
      return hash.includes(anchor);
    }
    return false;
  });
};

const AccordionList = ({
  className,
  items,
  titleClassName,
  contentClassName,
  iconText,
  openIconClassName = 'fa-minus',
  closeIconClassName = 'fa-plus',
}) => {
  const hash = getHash();
  const anchorIndex = getItemAnchorIndex(items, getHash());
  const [active, setActive] = useState(null);

  useEffect(() => {
    if (hash && anchorIndex !== -1) {
      document.querySelector(hash).scrollIntoView({
        behavior: 'smooth',
      });
      setActive(anchorIndex);
    }
  }, [anchorIndex]);

  return (
    <>
      {items.map((item, idx) => {
        if (item) {
          const { title, content, anchor } = item;
          const isOpen = idx === active;
          return (
            <div
              id={anchor}
              key={`accordion-${idx}`}
              className={classnames(
                styles.item,
                styles.list,
                className,
                isOpen && openClassName,
              )}
            >
              <div
                className={classnames(styles.title, titleClassName)}
                onClick={() => setActive(isOpen ? null : idx)}
              >
                <div>{title}</div>
                <div>
                  {iconText && (
                    <span className={styles.iconText}>{iconText}</span>
                  )}
                  <i
                    className={`fa-solid ${
                      isOpen ? openIconClassName : closeIconClassName
                    }`}
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <Collapse
                isOpen={isOpen}
                className={classnames(
                  'collapse-css-transition',
                  styles.content,
                  contentClassName,
                  isOpen && styles.open,
                  isOpen && 'open',
                )}
              >
                {content}
              </Collapse>
            </div>
          );
        }
      })}
    </>
  );
};

export default AccordionList;
