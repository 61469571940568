import React, { useEffect, createRef } from 'react';
import lottie from 'lottie-web';

const Lottie = ({ className, name }) => {
  const containerRef = createRef();
  const animationRef = createRef();

  useEffect(() => {
    if (name) {
      import(`../animations/${name}`).then(({ default: data }) => {
        animationRef.current = lottie.loadAnimation({
          container: containerRef.current,
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: data,
        });
      });

      return () => {
        if (animationRef.current) {
          animationRef.current.destroy();
        }
      };
    }
  }, []);

  return <div name={name} className={className} ref={containerRef} />;
};

export default Lottie;
