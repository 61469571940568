import React from 'react';
import classnames from 'classnames';
import useContentfulTerms from '../hooks/useContentfulTerms';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import AccordionList from '../components/AccordionList';
import { ConfusionPreFooterLight } from '../components/PreFooter';
import styles from './styles/terms.module.scss';

const Terms = () => {
  const items = useContentfulTerms();

  return (
    <Layout>
      <SEO title="Terms" />
      <div className={styles.header}>
        <div className="container">
          <div className={styles.title}>
            <span>Ts & Cs and all that stuff.</span>
          </div>
        </div>
      </div>
      <div className={classnames('container', styles.container)}>
        <AccordionList items={items} />
      </div>
      <ConfusionPreFooterLight />
    </Layout>
  );
};

export default Terms;
