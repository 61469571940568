import React from 'react';
import styles from './styles/PreFooter.module.scss';
import LinkButton from './LinkButton';
import Lottie from './Lottie';
import QSvg from '../images/q/q-cross.svg';
import CurveDown from '../images/layout/curve-down.svg';
import {
  ADVISER_CONTENT_PILLAR_URL,
  GET_STARTED_MS_BROKER_ROUTE,
} from '../constants/routes';
import cx from 'classnames';

export const TakeControlPreFooter = ({ basePath = '' }) => {
  return (
    <div className={styles.container}>
      <QSvg className={styles.cross} />
      <div className={styles.title}>Take control of your insurance now</div>
      <LinkButton
        className={styles.button}
        href={`${basePath}/get-started`}
        background="#34363E"
      >
        Join for free
      </LinkButton>
    </div>
  );
};

export const BossPreFooter = ({ basePath = '' }) => {
  return (
    <div className={styles.container}>
      <Lottie className={styles.boss} name="Q_Swagger" />
      <div className={styles.title}>
        Join the <span className={styles.blue}>#bethebossofyourinsurance</span>{' '}
        movement.
      </div>
      <LinkButton
        className={styles.button}
        href={`${basePath}/get-started`}
        background="#34363E"
      >
        Join for free
      </LinkButton>
    </div>
  );
};

export const JoinPreFooter = () => {
  return (
    <div className={styles.container}>
      <QSvg className={styles.cross} />
      <div className={styles.title}>
        Join thousands of kiwis on Quashed today.
      </div>
      <LinkButton
        className={styles.button}
        href="/v3/get-started"
        background="#34363E"
      >
        Join for free
      </LinkButton>
    </div>
  );
};

export const ConfusionPreFooter = () => (
  <div className={styles.container2}>
    <CurveDown className={styles.curve} />
    <div className={styles.content}>
      <div className={styles.title}>
        Join 20,000+ Kiwis that have saved <br />
        their insurance with Quashed.
      </div>
      <div className={styles.subtitle}>
        We’re New Zealand’s first and only insurance wallet and marketplace
      </div>
      <LinkButton
        background="#54A702"
        className={styles.btn}
        eventName="ms-sign-up-cta"
        href="/get-started"
      >
        Get started - it’s free
      </LinkButton>
    </div>
  </div>
);

export const ConfusionPreFooterLight = () => (
  <div className={styles.containerConfusionLight}>
    <div className={styles.content}>
      <div className={styles.title}>
        We’re on a mission to quash <br />
        insurance confusion
      </div>
      <div className={styles.subtitle}>
        We don't just squash 'em, we quash 'em, to put you in control.
      </div>
      <LinkButton
        href="/get-started"
        background="#4187f5"
        className={styles.blueBtn}
      >
        Sign up
      </LinkButton>
    </div>
  </div>
);

export const AdviserPreFooter = () => (
  <div className={styles.container3}>
    <div className={styles.content}>
      <div className={styles.title}>
        Upgrade your insurance experience today
      </div>
      <div className={styles.subtitle}>
        Try our personalised insurance service.
      </div>
      <div className={styles.buttonContainer}>
        <LinkButton
          href={GET_STARTED_MS_BROKER_ROUTE}
          background="4187f5"
          className={styles.blueBtn}
        >
          Sign up today
        </LinkButton>
        <LinkButton
          href={ADVISER_CONTENT_PILLAR_URL}
          background="white"
          className={styles.whiteBtn}
        >
          Learn more
        </LinkButton>
      </div>
    </div>
  </div>
);

export const TextPreFooter = ({
  basePath = '',
  subTitleTop,
  title,
  subTitleBottom,
  buttonText = 'Get started',
  link = `${basePath}/get-started`,
}) => {
  return (
    <div className={cx(styles.container, styles.textPreFooterContainer)}>
      {subTitleTop && (
        <div className={styles.subTitleTop}>
          <h1>{subTitleTop}</h1>
        </div>
      )}

      <div className={styles.title}>{title}</div>

      {subTitleBottom && (
        <div className={styles.subTitleBottom}>
          <h1>{subTitleBottom}</h1>
        </div>
      )}

      <LinkButton className={styles.blueBtn} href={link} background="#4187F5">
        {buttonText}
      </LinkButton>
    </div>
  );
};
